import classNames from 'classnames';

export function CloudStatus({status}) {
  const css = classNames('float-start', {
    'bi-cloud-slash': status === 'disabled',
    'bi-cloud-check': status === 'online',
    'bi-cloud': status === 'offline',
    'bi-cloud-lightning': status === 'error',
    'bi-cloud-minus': status === 'unauthorized',
  });
  return <i className={css} style={{fontSize: '1.5em'}}></i>;
}
