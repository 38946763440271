export class Api {
  #apiKey;

  constructor(apiKey, sourceId) {
    this.#apiKey = apiKey;
    this.sourceId = sourceId;
  }

  #post(url, payload) {
    const init = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': this.#apiKey,
      },
      body: JSON.stringify({source_id: this.sourceId, version: process.env.REACT_APP_VERSION, ...payload}),
    };

    return fetch(url, init).then(response => {
      if (!response.ok)
        return Promise.reject(response.status);

      return response.json();
    }).then(data => {
      this.sourceId = data.source_id;
      return data;
    });
  }

  synchronize(payload) {
    return this.#post('/api/synchronize', payload);
  }

  register(payload) {
    return this.#post('/api/register', payload);
  }

  unregister() {
    return this.#post('/api/unregister', {});
  }
}
