import React from 'react';

import {localeSorter} from '../utils/string';

export function Actor({actor}) {
  return <span className="small me-1">{actor}</span>;
}

export function ActorsList({actors}) {
  return actors.sort(localeSorter).map(actor => <Actor key={actor} actor={actor}/>);
}
