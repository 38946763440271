import classNames from 'classnames';
import React from 'react';

import {Currency} from '../utils/Currency';

export function Amount({amount}) {
  return <span className="fw-bold">{new Currency(amount).str}</span>;
}

export function AmountBadge({amount, color}) {
  const bgColor = color || 'secondary';
  const css = classNames(`badge bg-${bgColor}` , {
    'text-secondary': bgColor === 'secondary-subtle',
  });
  return <span className={css}><Amount amount={amount}/></span>;
}
