import {AppEvent} from './AppEvent';

export class Notifications {
  static ask() {
    if (!window.Notification)
      return;

    window.Notification.requestPermission().then(permission => AppEvent.createNotifications(permission === 'granted').post());
  }
};
