import classNames from 'classnames';
import React from 'react';

export function TextBox({id, label, valid, help, ...props}) {
  const inputClass = classNames('form-control', {'is-invalid': !valid});
  const onChange = ev => props.onChange(ev.target.value);
  return (
      <div className="mb-3">
          <label className="form-label" htmlFor={id}>{label}</label>
          <input {...props} id={id} name={id} type="text" className={inputClass} onChange={onChange}/>
          {help ? <div className="small form-text text-muted">{help}</div> : null}
      </div>
  );
}
