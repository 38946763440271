import {Storage} from './Storage';

export class Serializer {
  static sizes({bills, transactions}) {
    const all = bills.concat(transactions);
    const unsent = all.filter(rec => rec.updater_id === 0 && !rec.sent);
    return {size: JSON.stringify(all).length, pending: unsent.length ? JSON.stringify(unsent).length : 0};
  }

  static pending() {
    const key = {updater_id: 0};

    return Promise.all([
      Storage.bills.notSent(key),
      Storage.transactions.notSent(key),
    ]).then(([bills, transactions]) => {
      return {bills, transactions};
    });
  }
};
