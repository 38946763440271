import Dexie from 'dexie';

import {Bill} from './Bill';
import {Config} from './Config';
import {Transaction} from './Transaction';

const db = new Dexie('holy_sheet');

// Uwaga: pola, które są kluczem nie mogą być typu boolean
db.version(1).stores({
  bill: '[id+source_id+updater_id+update_time], [id+source_id], source_id, updater_id',
  transaction: '[id+source_id+updater_id+update_time], [id+source_id], [bill_id+bill_source_id], [id+source_id+bill_id+bill_source_id], source_id, bill_source_id, updater_id',
  config: 'id',
});

export class Storage {
  static get #id() {
    return Date.now();
  }

  static get nextKey() {
    return {id: Storage.#id, source_id: 0};
  }

  static get updateKey() {
    return {updater_id: 0, update_time: Storage.#id};
  }

  static get transactions() {
    return new Transaction(db.table('transaction'));
  }

  static get bills() {
    return new Bill(db.table('bill'));
  }

  static get #config() {
    return new Config(db.table('config'));
  }

  static getConfig() {
    return Storage.#config.get();
  }

  static setConfig(update) {
    return db.transaction('rw', db.table('config'), () => {
      const configDb = Storage.#config;
      return configDb.get().then(config => configDb.set({...config, ...update}));
    });
  }

  static withTransaction(updater) {
    return db.transaction('rw', db.table('bill'), db.table('transaction'), updater);
  }
}

// jeśli trzeba wygenerować wiele kluczy na raz
export class KeyGenerator {
  #key

  constructor() {
    this.#key = Storage.nextKey;
  }

  get next() {
    this.#key.id++;
    return this.#key;
  }
}
