export class Currency {
  #amount;

  static #parse(str) {
    return Number(str.replace(',', '.')).toFixed(2);
  }

  get #cents() {
    return Math.abs(Math.trunc(this.#amount % 100)).toString().padStart(2, '0');
  }

  get #units() {
    return Math.abs(Math.trunc(this.#amount / 100)).toString();
  }

  get #strSign() {
    return this.sign === -1 ? '-' : '';
  }

  constructor(amount) {
    this.#amount = Math.round(amount);
  }

  static fromFloat(value) {
    return new Currency(100 * value)
  }

  get amount() {
    return this.#amount;
  }

  get sign() {
    return Math.sign(this.#amount);
  }

  get str() {
    return `${this.#strSign}${this.#units},${this.#cents}`;
  }
}
