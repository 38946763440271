import classNames from 'classnames';
import React, {useEffect} from 'react';
import {Form, Link, redirect, useLoaderData, useRevalidator, useSubmit} from 'react-router-dom';

import {AppEvent} from '../AppEvent';
import {AppRoutes, BillRoutes, TransRoutes} from '../AppRoutes';
import {ActorsList} from '../components/Actor';
import {Amount, AmountBadge} from '../components/Amount';
import {Arrow} from '../components/Arrow';
import {AddButton} from '../components/forms/AddButton';
import {SearchBar} from '../components/SearchBar';
import {ShortDate} from '../components/ShortDate';
import {Summary} from '../components/Summary';
import {Unsent} from '../components/Unsent';
import {Storage} from '../db/Storage';
import {Calculator} from '../utils/Calculator';
import {searchAnyPred} from '../utils/search';

export async function loader({params, request}) {
  const billKey = BillRoutes.parseParams(params);
  const bill = await Storage.bills.one(billKey);
  const billRefKey = TransRoutes.parseBillParams(params);
  let records = await Storage.transactions.many(billRefKey);

  const summary = new Calculator().process(records).sum;

  const url = new URL(request.url);

  const focus = url.searchParams.get('focus') || '';
  if (summary.hasOwnProperty(focus)) {
    const focusPred = rec => rec.from.includes(focus) || rec.to.includes(focus);
    records = records.filter(focusPred);
  }

  const search = url.searchParams.get('search') || '';
  if (search.trim()) {
    const filterPred = searchAnyPred(search);
    records = records.filter(rec => filterPred(rec.label));
  }

  return {records, summary, search, focus, bill};
}

export async function action({params}) {
  const transKey = {...Storage.nextKey, ...TransRoutes.parseBillParams(params)};
  return redirect(new TransRoutes(transKey).edit);
}

function Trans({trans, flow, readOnlyBill}) {
  const readOnly = readOnlyBill || trans.settled;
  const amountCss = classNames('text-center align-middle', {
    'text-primary': !readOnly,
  });
  return (
    <>
      <tr>
        <td className="text-center align-middle" rowSpan="2"><ShortDate timestamp={trans.id}/></td>
        <td className={amountCss}><Amount amount={trans.amount}/></td>
        <td>
          {readOnly ? trans.label : <Link to={new TransRoutes(trans).edit}>{trans.label}</Link>}
          {trans.sent || trans.updater_id ? null : <Unsent/>}
        </td>
      </tr>
      <tr>
        <td className="text-secondary align-middle" colSpan="2">
          <div className="d-flex justify-content-between align-items-center">
            <span><ActorsList actors={trans.from}/><Arrow/><ActorsList actors={trans.to}/></span>
            {typeof flow === 'number' ? <AmountBadge amount={flow}/> : null}
          </div>
        </td>
      </tr>
    </>
  );
}

function Transactions({records, focus, readOnlyBill}) {
  return (
    <table className="table table-bordered table-sm small">
      <tbody>
        {records.map(trans => <Trans key={AppRoutes.keyPath(trans)} readOnlyBill={readOnlyBill} trans={trans} flow={focus ? Calculator.flowForActor(trans, focus) : null}/>)}
      </tbody>
    </table>
  );
}

function TransFilter({search, focus, title}) {
  const submit = useSubmit();
  const searchTrans = event => submit(event.currentTarget.form, {replace: true});
  return (
    <Form id="trans-filter">
      <SearchBar search={search} title={title} onChange={searchTrans}/>
      <input type="hidden" name="focus" value={focus}/>
    </Form>
  );
}

export function BillTrans() {
  const {records, summary, search, focus, bill} = useLoaderData();
  const submit = useSubmit();
  const revalidator = useRevalidator();

  useEffect(() => {
    const onMsg = event => {
      const {type, status} = event.data;
      if (type === AppEvent.SYNC_STATUS && status === 'online') {
        revalidator.revalidate();
      }
    };

    AppEvent.addListener(onMsg);
    return () => AppEvent.removeListener(onMsg);
  }, [revalidator]);

  const selectActor = actor => {
    const form = document.forms['trans-filter'];
    form.elements['focus'].value = focus === actor ? '' : actor;
    submit(form, {replace: true});
  };

  const showSummary = focus || !search.trim();
  const readOnlyBill = bill.read_only;
  return (
    <>
      <TransFilter search={search} focus={focus} title={bill.label}/>
      {showSummary ? <Summary summary={summary} focus={focus} onClick={selectActor}/> : null}
      <Transactions records={records} focus={focus} readOnlyBill={readOnlyBill}/>
      {readOnlyBill ? null : <Form method="post"><AddButton/></Form>}
    </>
  );
}
