import {RecordLog} from './RecordLog';
import {createBillRecordRef, createRecordRef} from './RecordRef';

export class Transaction extends RecordLog {
  constructor(table) {
    const defaultValue = {
      from: [], expression: '', amount: 0, label: '', to: [], deleted: 0, sent: 0, settled: 0
    };

    super(table, defaultValue);
  }

  #sort(records) {
    return records.sort((a, b) => b.id - a.id);
  }

  billRef(record) {
    const {bill_id, bill_source_id} = record;
    return {id: bill_id, source_id: bill_source_id};
  }

  many(key) {
    return super.many(key).then(this.#sort);
  }

  pruneOrphans(bills) {
    const billRefs = new Set(bills.map(bill => createRecordRef(bill)));
    return super.many().then(records => Promise.all(records.map(record => {
      const billRef = createBillRecordRef(record);
      if (billRefs.has(billRef))
        return Promise.resolve();

      return this.remove(this.ref(record));
    })));
  }
};
