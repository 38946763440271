import classNames from 'classnames';
import React, {useEffect} from 'react';
import {Form, Link, redirect, useLoaderData, useOutletContext, useRevalidator} from 'react-router-dom';

import {AppEvent} from '../AppEvent';
import {BillRoutes} from '../AppRoutes';
import {ActorsList} from '../components/Actor';
import {AmountBadge} from '../components/Amount';
import {AppVer} from '../components/AppVer';
import {CloudStatus} from '../components/CloudStatus';
import {AddButton} from '../components/forms/AddButton';
import {Unsent} from '../components/Unsent';
import {Storage} from '../db/Storage';
import {Serializer} from '../db/Serializer';
import {BillState} from '../utils/BillState';

export async function loader({params}) {
  const [bills, {size, pending}] = await Promise.all([
    Storage.bills.many(), Storage.transactions.many(),
  ]).then(([bills, transactions]) => {
    const states = new BillState().process(transactions).finalize();
    return [
      bills.map(bill => {
        return {...bill, state: {...states.one(bill)}};
      }),
      Serializer.sizes({bills, transactions}),
    ];
  });

  return {bills, size, pending};
}

export async function action({request, params}) {
  const key = Storage.nextKey;
  return redirect(new BillRoutes(key).edit);
}

function stateIconClass(bill) {
  if (bill.read_only)
    return 'bi-ban';

  // state może być undefined dla rachunków bez transakcji
  if (bill.state.settled === 0)
    return null;

  return bill.state.unsettled || !bill.state.balanced ? 'bi-unlock' : 'bi-lock';
}

function StateIcon({bill}) {
  const className = stateIconClass(bill);
  return className ? <i className={`bi ${className} small me-1`}></i> : null;
}

function EditButton({bill}) {
  const css = classNames('btn btn-outline-primary btn-sm', {
    'invisible': bill.read_only,
  });
  return <Link to={new BillRoutes(bill).edit} role="button" className={css}>Edytuj</Link>;
}

function BillLabel({bill}) {
  return (
    <>
      <StateIcon bill={bill}/>
      <Link to={new BillRoutes(bill).path}>{bill.label}</Link>
      {bill.sent || bill.updater_id ? null : <Unsent/>}
    </>
  );
}

function Debt({bill}) {
  const color = bill.state.settled && !bill.state.balanced ? 'danger' : 'secondary-subtle';
  return (
    <span className="small">
      <AmountBadge amount={bill.state.debt} color={color}/>
    </span>
  );
}

function Bill({bill}) {
  return (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <div><BillLabel bill={bill}/></div>
          <div><ActorsList actors={bill.actors}/></div>
        </div>
        <div className="d-flex flex-column align-items-end text-secondary">
          <div className="mb-1"><EditButton bill={bill}/></div>
          <div><Debt bill={bill}/></div>
        </div>
      </div>
    </>
  );
}

function Header({cloud}) {
  return (
    <nav className="navbar">
      <Link to="/cloud/config/edit"><CloudStatus status={cloud}/></Link>
      <AppVer/>
    </nav>
  );
}

function DbSize({size}) {
  const kb = size / 1024;
  return <span className="float-start"><i className="bi-database me-1"></i>{kb.toFixed(2)}k</span>;
}

function UploadSize({size}) {
  const kb = size / 1024;
  return kb ? <span className="float-end"><i className="bi-upload me-1"></i>{kb.toFixed(2)}k</span> : null;
}

function Separator() {
  return <span className="mx-1">/</span>;
}

function TransCount({count}) {
  return (
    <>
      <i className="bi bi-list-ol me-1"></i>{count}
    </>
  );
}

export function Bills(props) {
  const {bills, size, pending} = useLoaderData();
  const {cloud} = useOutletContext();
  const revalidator = useRevalidator();

  useEffect(() => {
    const onMsg = event => {
      const {type, status} = event.data;
      if (type === AppEvent.SYNC_STATUS && status === 'online') {
        revalidator.revalidate();
      }
    };

    AppEvent.addListener(onMsg);
    return () => AppEvent.removeListener(onMsg);
  }, [revalidator]);

  const transCount = bills.reduce((prev, bill) => prev + bill.state.settled + bill.state.unsettled, 0);

  return (
    <>
      <Header cloud={cloud.status}/>
      <ul className="list-group">
        {bills.map(bill => <li key={BillRoutes.keyPath(bill)} className="list-group-item"><Bill bill={bill}/></li>)}
      </ul>
      <div className="small text-secondary mt-1 mb-5">
        <DbSize size={size}/><Separator/><TransCount count={transCount}/>
        <UploadSize size={pending}/>
      </div>
      <Form method="post"><AddButton/></Form>
    </>
  );
}
