import {Api} from './Api';
import {AppEvent} from '../AppEvent';
import {Serializer} from '../db/Serializer';
import {Storage} from '../db/Storage';
import {BackgroundSync} from '../sw/BackgroundSync';

export class Synchronization {
  static #updateDb(table, sent, downloaded) {
    sent = sent.map(record => table.markSent(table.key(record)));
    downloaded = downloaded.map(record => table.add(record));
    return Promise.all(sent.concat(downloaded));
  }

  static #processUpdate(request, response) {
    return Promise.all([
      Synchronization.#updateDb(Storage.bills, request.bills, response.bills),
      Synchronization.#updateDb(Storage.transactions, request.transactions, response.transactions),
      Storage.setConfig({synchToken: Date.now(), sourceId: response.source_id}),
    ]);
  }

  static async #synchronize(request) {
    const {synchToken, apiKey, sourceId} = await Storage.getConfig();
    const payload = {...request, token: synchToken, source_id: sourceId};
    return new Api(apiKey, sourceId).synchronize(payload).then(response => Synchronization.#processUpdate(request, response));
  }

  static #processUpload(processor) {
    return Promise.all([
      Storage.getConfig(),
      Serializer.pending(),
    ]).then(([config, data]) => {
      if (!config.apiKey)
        return AppEvent.createSync('disabled');

      return processor(data).then(() => {
        return Promise.all([
          Storage.bills.prune(),
          Storage.transactions.prune()
        ]).then(() => Storage.bills.many()).then(bills => Storage.transactions.pruneOrphans(bills));
      }).then(() => AppEvent.createSync('online')).catch(err => {
        const FORBIDDEN = 403;
        const event = AppEvent.createSync(err === FORBIDDEN ? 'unauthorized' : 'error');
        return Promise.reject(event);
      });
    });
  }

  static commit() {
    const processor = data => {
      if (data.bills.length || data.transactions.length)
        return Synchronization.#synchronize(data)

      return Promise.resolve();
    };

    return Synchronization.#processUpload(processor).catch(event => {
      BackgroundSync.db();
      return event;
    }).then(event => event.post());
  }

  static #processRequest() {
    return Synchronization.#processUpload(data => Synchronization.#synchronize(data));
  }

  static request() {
    return Synchronization.#processRequest().catch(event => {
      BackgroundSync.db();
      return event;
    }).then(event => event.post());
  }

  static sWRequest() {
    return Synchronization.#processRequest().then(event => event.sWPost(), event => event.sWPost());
  }

  static init() {
    return Synchronization.request().then(() => BackgroundSync.init());
  }

  static reset() {
    return Promise.all([
      Storage.bills.clear(),
      Storage.transactions.clear(),
      Storage.setConfig({synchToken: 0, sourceId: 0}),
    ]);
  }
};
