import {RecordLog} from './RecordLog';
import {localeSorter} from '../utils/string';

export class Bill extends RecordLog {
  constructor(table) {
    const defaultValue = {
      label: '', actors: [], deleted: 0, sent: 0, read_only: 0,
    };

    super(table, defaultValue);
  }

  #sort(records) {
    return records.sort((a, b) => localeSorter(a.label, b.label));
  }

  many(key) {
    return super.many(key).then(this.#sort);
  }
};
