import classNames from 'classnames';
import React from 'react';

import {Actor} from './Actor';
import {AmountBadge} from './Amount';
import {Currency} from '../utils/Currency';
import {localeSorter} from '../utils/string';

function amountColor(amount) {
  const sign = new Currency(amount).sign;
  if (sign === -1) return 'success';
  if (sign === 1) return 'danger';
  return 'secondary';
}

function Debt({actor, amount, highlight, ...props}) {
  const css = classNames('list-group-item d-flex p-2 align-items-center justify-content-between', {'bg-warning': highlight});
  const color = amountColor(amount);
  return (
    <li {...props} className={css}>
      <Actor actor={actor}/>
      <AmountBadge amount={amount} color={color}/>
    </li>
  );
}

export function Summary({summary, focus, onClick}) {
  const sorted = Object.keys(summary).sort(localeSorter);
  return (
    <ul className="list-group list-group-horiozontal mb-1">
      {sorted.map(actor => {
        const amount = Math.trunc(summary[actor]);
        return <Debt key={actor} actor={actor} highlight={focus === actor} amount={amount} onClick={() => onClick(actor)}/>
      })}
    </ul>
  );
}
