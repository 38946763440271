import {Db} from './Db';

export class Config extends Db {
  #key = {id: 0};

  constructor(table) {
    const defaultValue = {
      apiKey: '', synchToken: 0, sourceId: 0, subscribedSourceId: null,
    };

    super(table, defaultValue);
  }

  get() {
    return super.one(this.#key);
  }

  set(value) {
    return super.replace({...value, ...this.#key});
  }
};
