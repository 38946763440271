import {Storage} from '../db/Storage';

export class BackgroundSync {
  static #register(type) {
    // w trybie prywatnym nie ma dostępu do SW
    if (!navigator.serviceWorker)
      return;

    navigator.serviceWorker.ready.then(registration => {
      if (registration.sync)
        return registration.sync.register(type);

      // Firefox nie obsługuje SyncManagera, a controller nie jest dostępny zaraz po rejestracji sw
      if (navigator.serviceWorker.controller)
        navigator.serviceWorker.controller.postMessage({type});
    });
  }

  static db() {
    if (navigator.onLine)
      return;

    Storage.getConfig().then(config => BackgroundSync.#register(`db.${config.synchToken}`));
  }

  static init() {
    BackgroundSync.#register('init');
  }
};
