export class Db {
  #table;
  #defaultValue;

  constructor(table, defaultValue) {
    this.#table = table;
    this.#defaultValue = defaultValue;
  }

  replace(record) {
    return this.#table.put({...this.#defaultValue, ...record});
  }

  add(record) {
    return this.#table.add({...this.#defaultValue, ...record});
  }

  update(key, changes) {
    return this.#table.where(key).modify(changes);
  }

  all() {
    return this.#table.toCollection();
  }

  query(key) {
    return this.#table.where(key);
  }

  find(key) {
    return this.#table.get(key).then(record => {
      if (record)
        return [record, true];

      return [{...this.#defaultValue, ...key}, false];
    });
  }

  one(key) {
    return this.#table.get(key).then(record => record ?? {...this.#defaultValue, ...key});
  }

  remove(key) {
    return this.#table.where(key).delete();
  }

  clear() {
    return this.#table.clear();
  }
};
