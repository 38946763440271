import {redirect} from 'react-router-dom';

import {Push} from '../api/Push';
import {Synchronization} from '../api/Synchronization';
import {Storage} from '../db/Storage';

export async function action({request, params}) {
  await Storage.getConfig().then(({apiKey}) => {
    if (!apiKey)
      return;

    return Push.stop().catch(() => undefined).then(() => Storage.setConfig({apiKey: ''}));
  });

  Synchronization.init();
  return redirect('/');
}
