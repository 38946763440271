import React, {useState} from 'react';
import {Form, useLoaderData, redirect} from 'react-router-dom';

import {Synchronization} from '../api/Synchronization';
import {BillRoutes, TransRoutes} from '../AppRoutes';
import {Select} from '../components/forms/Select';
import {createRecordRef, parseBillRecordRef, parseRecordRef} from '../db/RecordRef';
import {KeyGenerator, Storage} from '../db/Storage';
import {SubmitButton} from '../components/forms/SubmitButton';
import {TextBox} from '../components/forms/TextBox';
import {Calculator} from '../utils/Calculator';
import {Currency} from '../utils/Currency';
import {Expression} from '../utils/Expression';
import {Settlement} from '../utils/Settlement';
import {localeSorter} from '../utils/string';

export async function loader({params}) {
  const billKey = BillRoutes.parseParams(params);
  const sourceBill = await Storage.bills.one(billKey);
  const targetBills = await Storage.bills.many();
  return {sourceBill, targetBills};
}

function createAmountExpression(amount, rate) {
  const amountStr = new Currency(amount).str;
  if (new Expression(rate).value === 1)
    return amountStr

  return `${amountStr}*${rate}`
}

function calculateTransValue(transAmount, rate) {
  const expression = createAmountExpression(transAmount, rate);
  const value = new Expression(expression).value;
  const amount = Currency.fromFloat(value).amount;
  return {amount, expression};
}

function createTransactions(settlementRecords, keyGen, targetBillRefKey, label, rate) {
  return settlementRecords.map(rec => {
    return {
      ...rec,
      ...keyGen.next, ...targetBillRefKey, ...Storage.updateKey,
      ...calculateTransValue(rec.amount, rate || '1'),
      label: `Rozliczenie${label}`,
    };
  });
}

function reverseFlow(record) {
  return {...record, from: record.to, to: record.from};
}

function updateActors(bill, newActors) {
  const uniqueActors = new Set([...bill.actors, ...newActors]);
  const actors = [...uniqueActors].sort(localeSorter);
  return Storage.bills.add({...bill, ...Storage.updateKey, actors});
}

export async function action({request, params}) {
  const form = await request.formData().then(data => Object.fromEntries(data));
  const [billKey, billRefKey] = [BillRoutes.parseParams(params), TransRoutes.parseBillParams(params)];
  const [targetBillKey, targetBillRefKey] = [parseRecordRef(form.target), parseBillRecordRef(form.target)];
  const sameBill = createRecordRef(billKey) === createRecordRef(targetBillKey);

  const [sourceBill, records] = await Promise.all([
    Storage.bills.one(billKey), Storage.transactions.many(billRefKey),
  ]);
  const targetBill = sameBill ? sourceBill : await Storage.bills.one(targetBillKey);

  const summary = new Calculator().process(records).sum;
  const settleRecords = new Settlement().process(summary).records;
  const keyGen = new KeyGenerator();

  const sourceTrans = records.map(rec => {
    return {...rec, ...Storage.updateKey, settled: 1};
  });
  const sourceLabel = sameBill ? '' : ` w ${targetBill.label}`;
  const sourceSettle = createTransactions(settleRecords, keyGen, billRefKey, sourceLabel).map(rec => {
    return {...rec, settled: 1};
  });

  const dbOper = [sourceTrans.concat(sourceSettle).map(rec => Storage.transactions.add(rec))];

  if (!sameBill) {
    const targetLabel = ` ${sourceBill.label}`;
    const targetSettle = createTransactions(settleRecords.map(reverseFlow), keyGen, targetBillRefKey, targetLabel, form.rate);
    dbOper.push(
      targetSettle.map(rec => Storage.transactions.add(rec)),
      updateActors(targetBill, Object.keys(summary))
    );
  }

  await Promise.all(dbOper);
  Synchronization.commit();
  return redirect('/');
}

function createSelectItems(bills) {
  return bills.map(({label, ...record}) => {
    return {
      id: createRecordRef(record),
      label,
    };
  });
}

export function BillSettle() {
  const billPrompt = {id: 'none', label: '[wybierz rachunek]'};

  const [state, setState] = useState({targetBillRef: billPrompt.id, rate: '1'});
  const {sourceBill, targetBills} = useLoaderData();

  const onRateChange = value => {
    setState(prev => {
      return {...prev, rate: value};
    });
  };

  const onSettleBillChange = value => {
    setState(prev => {
      return {...prev, targetBillRef: value};
    });
  };

  const targetItems = [billPrompt, ...createSelectItems(targetBills)];

  const sourceItems = createSelectItems([sourceBill]);

  const targetBillValid = state.targetBillRef !== billPrompt.id;
  const rateValid = new Expression(state.rate).value > 0;
  const sameBill = state.targetBillRef === createRecordRef(sourceBill);

  return (
    <Form method="post" replace>
      <Select id="source" label="Rachunek rozliczany" valid disabled items={sourceItems} help="zostanie zamknięty"/>
      <Select id="target" label="Rachunek docelowy" valid={targetBillValid} items={targetItems} value={state.targetBillRef} onChange={onSettleBillChange}
        help="do którego zostanie dołączyny bilans rachunku źródłowego"/>
      {sameBill || !targetBillValid ? null :
        <TextBox id="rate" placeholder="np. EUR/PLN" label="Kurs waluty" valid={rateValid} disabled={sameBill} value={state.rate} onChange={onRateChange}
          help="kurs przeliczenia z waluty rachunku rozliczanego na walutę rachunku docelowego"/>
      }
      <SubmitButton label="Rozlicz" valid={targetBillValid && (rateValid || sameBill)}/>
    </Form>
  )
}
