import classNames from 'classnames';
import React from 'react';

export function Select({id, label, valid, help, items, ...props}) {
  const inputClass = classNames('form-select', {'is-invalid': !valid});
  const onChange = ev => props.onChange(ev.target.value);

  return (
      <div className="mb-3">
          <label className="form-label" htmlFor={id}>{label}</label>
          <select {...props} id={id} name={id} className={inputClass} onChange={onChange}>
            {items.map(({id, label}) => <option key={id} value={id}>{label}</option>)}
          </select>
          {help ? <div className="small form-text text-muted">{help}</div> : null}
      </div>
  );
}
