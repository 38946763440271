import React, {useState} from 'react';
import {Form, redirect, useLoaderData, useOutletContext} from 'react-router-dom';

import {Push} from '../api/Push';
import {Synchronization} from '../api/Synchronization';
import {PleaseWait} from '../components/PleaseWait';
import {Storage} from '../db/Storage';
import {DeleteButton} from '../components/forms/DeleteButton';
import {SubmitButton} from '../components/forms/SubmitButton';
import {TextBox} from '../components/forms/TextBox';
import classNames from 'classnames';

export function loader({params}) {
  return Storage.getConfig().then(config => {
    return {config};
  });
}

export async function action({request, params}) {
  await Promise.all([
    request.formData().then(data => Object.fromEntries(data)),
    Storage.getConfig(),
  ]).then(([updates, {apiKey, synchToken}]) => {
    if (updates.api_key === apiKey)
      return;

    return Storage.setConfig({apiKey: updates.api_key}).then(() => {
      // jeśli w ogóle nie było synchronizacji, to nie usuwamy danych z bazy
      // (przypadek migracji)
      if (synchToken === 0)
        return;

      return Synchronization.reset();
    });
  }).then(() => Push.reset());

  Synchronization.init();
  return redirect('/');
}

function PushStatus({hash, subscribedSourceId}) {
  return <div className="small text-secondary float-end">{hash}[{subscribedSourceId}]</div>;
}

function Source({sourceId}) {
  return <div className="small text-secondary float-start">[{sourceId}]</div>;
}

function Notifications({enabled}) {
  const css = classNames('float-end ms-1 small text-secondary', {
    'bi-bell-slash': !enabled,
    'bi-bell': enabled,
  });

  return <i className={css}></i>
}

function Status({config, push, notifications}) {
  return (
    <div className="row mb-3">
      <div className="col-auto"><Source sourceId={config.sourceId}/></div>
      <div className="col">
        <Notifications enabled={notifications}/>
        <PushStatus hash={push.hash} subscribedSourceId={config.subscribedSourceId}/>
      </div>
    </div>
  );
}

export function CloudConfigEdit() {
  const {push, notifications} = useOutletContext();
  const {config} = useLoaderData();
  const [state, setState] = useState({apiKey: config.apiKey, unregistering: false});

  const onKeyChange = value => {
    setState(prev => {
      return {...prev, apiKey: value};
    });
  };

  const onDelete = () => {
    setState(prev => {
      return {...prev, unregistering: true};
    });
  };

  return state.unregistering ?
    <PleaseWait>Momencik...</PleaseWait> :
    <>
      <Form method="post" replace>
        <TextBox id="api_key" placeholder="Klucz dostępu" label="Klucz dostępu" defaultValue={config.apiKey} valid={state.apiKey} onChange={onKeyChange}/>
        <Status config={config} push={push} notifications={notifications}/>
        <SubmitButton valid={state.apiKey}/>
      </Form>
      <Form method="post" action="/cloud/config/delete" replace onSubmit={onDelete}>
        <DeleteButton/>
      </Form>
    </>;
}
