export class AppRoutes {
  static keyPath(key) {
    return `${key.source_id}/${key.id}`;
  }

  #path;

  constructor(store) {
    this.#path = store.split('/').filter(elem => elem);
  }

  get path() {
    return '/' + this.#path.join('/');
  }

  item(key) {
    return this.#path.push(AppRoutes.keyPath(key));
  }

  get edit() {
    return `${this.path}/edit`;
  }

  get delete() {
    return `${this.path}/delete`;
  }
};

export class BillRoutes extends AppRoutes {
  static params = {id: ':billId', source_id: ':billSourceId'};

  static parseParams({billId, billSourceId}) {
    return {id: parseInt(billId, 10), source_id: parseInt(billSourceId, 10)};
  }

  constructor(key) {
    super('bills');
    if (key) {
      this.item(key);
    }
  }

  get settle() {
    return `${this.path}/settle`;
  }
};

export class TransRoutes extends AppRoutes {
  static billRefParams(params) {
    return {bill_id: params.id, bill_source_id: params.source_id};
  }

  static params = {id: ':transId', source_id: ':transSourceId', ...TransRoutes.billRefParams(BillRoutes.params)};

  static parseParams({transId, transSourceId}) {
    return {id: parseInt(transId, 10), source_id: parseInt(transSourceId, 10)};
  }

  static parseBillParams(params) {
    return TransRoutes.billRefParams(BillRoutes.parseParams(params));
  }

  constructor(key) {
    const billPath = new BillRoutes({id: key.bill_id, source_id: key.bill_source_id}).path;
    super(`${billPath}/trans`);
    if (key) {
      this.item(key);
    }
  }
};
