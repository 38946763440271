export class Calculator {
  constructor() {
    this.sum = {};
  }

  #distribute(amount, actors) {
    const share = amount / actors.length;
    actors.forEach(actor => {
        if (this.sum.hasOwnProperty(actor)) this.sum[actor] += share;
        else this.sum[actor] = share;
    })
  }

  process(records) {
    records.forEach(trans => {
        this.#distribute(-trans.amount, trans.from);
        this.#distribute(trans.amount, trans.to);
    });

    return this;
  }

  static flowForActor(trans, actor) {
    return new Calculator().process([trans]).sum[actor];
  }

  get debt() {
    const amounts = Object.entries(this.sum).map(sum => sum[1]).filter(amount => amount > 0);
    return amounts.reduce((prev, amount) => prev + amount, 0);
  }
}
