import {Currency} from './Currency';

class Balance {
  constructor(summary) {
    const sums = Object.entries(summary).map(sum => {
      return {'actor': sum[0], 'amount': sum[1]};
    });

    this.debits = sums.filter(sum => sum.amount > 0);
    this.credits = sums.filter(sum => sum.amount < 0).map(sum => {
      return {...sum, amount: -sum.amount};
    });
  }

  get settled() {
    return !this.debits.length || !this.credits.length;
  }

  next() {
    this.credits.sort((a, b) => b.amount - a.amount);
    this.debits.sort((a, b) => b.amount - a.amount);

    const amount = Math.min(this.credits[0].amount, this.debits[0].amount);
    return {from: [this.debits[0].actor], to: [this.credits[0].actor], amount};
  }

  static #post(sums, amount) {
    sums[0].amount -= amount;
    if (sums[0].amount < 1)
      sums.shift();
  }

  settle(amount) {
    Balance.#post(this.credits, amount);
    Balance.#post(this.debits, amount);
  }
};

export class Settlement {
  constructor() {
    this.records = [];
  }

  process(summary) {
    const balance = new Balance(summary);
    while (!balance.settled) {
      const record = balance.next();
      if (new Currency(record.amount).amount)
        this.records.push(record);

      balance.settle(record.amount);
    }

    return this;
  }
};
