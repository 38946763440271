import React, {useState} from 'react';
import {Form, useLoaderData, redirect} from 'react-router-dom';

import {Synchronization} from '../api/Synchronization';
import {BillRoutes} from '../AppRoutes';
import {BackButton} from '../components/forms/BackButton';
import {DeleteButton} from '../components/forms/DeleteButton';
import {SettleButton} from '../components/forms/SettleButton';
import {SubmitButton} from '../components/forms/SubmitButton';
import {TextBox} from '../components/forms/TextBox';
import {Storage} from '../db/Storage';
import {localeSorter} from '../utils/string';

export async function loader({params}) {
  const billKey = BillRoutes.parseParams(params);
  const [bill, edit] = await Storage.bills.find(billKey);
  return {bill, edit};
}

function parseActors(actors) {
  return actors.trim().toLocaleUpperCase().split(/ +/);
}

function createBill({label, actors}) {
  return {label: label.trim(), actors: parseActors(actors).sort(localeSorter)};
}

function parseForm(data) {
  const updates = Object.fromEntries(data);
  return createBill(updates);
}

function saveBill(bill, billKey, updateKey) {
  return Storage.bills.add({...bill, ...billKey, ...updateKey});
}

export async function action({request, params}) {
  const bill = await request.formData().then(parseForm);
  const billKey = BillRoutes.parseParams(params);
  const updateKey = Storage.updateKey;
  await saveBill(bill, billKey, updateKey);
  Synchronization.commit();
  return redirect('/');
}

function validateLabel(value) {
  return value.trim();
}

function validateActors(value) {
  return value.trim() && parseActors(value).length;
}

function DeleteForm({bill}) {
  return (
    <Form method="post" action={new BillRoutes(bill).delete} replace>
      <DeleteButton/>
    </Form>
  );
}

function SettleForm({bill}) {
  return (
    <Form action={new BillRoutes(bill).settle} replace>
      <SettleButton/>
    </Form>
  );
}

export function BillEdit() {
  const {bill, edit} = useLoaderData();
  const [state, setState] = useState({label: validateLabel(bill.label), actors: bill.actors.length});

  const onLabelChange = value => {
    setState(prev => {
      return {...prev, label: validateLabel(value)};
    });
  };

  const onActorsChange = value => {
    setState(prev => {
      return {...prev, actors: validateActors(value)};
    });
  };

  const readOnly = bill.read_only;
  return (
    <>
      <Form method="post" replace>
        <TextBox id="label" placeholder="Nazwa rachunku" label="Rachunek" defaultValue={bill.label} valid={state.label} disabled={readOnly} onChange={onLabelChange}/>
        <TextBox id="actors" placeholder="Ktoś ktoś ktoś" label="Osoby" defaultValue={bill.actors.join(' ')} valid={state.actors} disabled={readOnly} onChange={onActorsChange}/>
        {readOnly ? <BackButton to="/"/> : <SubmitButton valid={state.label && state.actors}/>}
      </Form>
      {readOnly ? null : <DeleteForm bill={bill}/>}
      {!readOnly && edit ? <SettleForm bill={bill}/> : null}
    </>
  );
}
