import {redirect} from 'react-router-dom';

import {Synchronization} from '../api/Synchronization';
import {BillRoutes, TransRoutes} from '../AppRoutes';
import {Storage} from '../db/Storage';

function isReadOnly(transKey) {
  return Storage.transactions.find(transKey).then(([trans, edit]) => {
    if (!edit)
      return false;

    if (trans.settled)
      return true;

    const billRef = Storage.transactions.billRef(trans);
    return Storage.bills.one(billRef).then(bill => bill.read_only);
  });
}

export async function action({request, params}) {
  const transKey = TransRoutes.parseParams(params);
  const readOnly = await isReadOnly(transKey);
  if (!readOnly) {
    const updateKey = Storage.updateKey;
    await Storage.transactions.delete(transKey, updateKey);
    Synchronization.commit();
  }

  const billKey = BillRoutes.parseParams(params);
  return redirect(new BillRoutes(billKey).path);
}
