import {Calculator} from './Calculator';
import {createRecordRef} from '../db/RecordRef';

export class BillState {
  #states;

  constructor(transactions) {
    this.#states = new Map();
  }

  #createDefault() {
    return {settled: 0, unsettled: 0, balanced: false, debt: 0, summary: new Calculator()};
  }

  #get({source_id, id}) {
    const ref = createRecordRef({source_id, id});
    return [ref, this.#states.get(ref) || this.#createDefault()];
  }

  process(transactions) {
    for (const trans of transactions) {
      const [ref, state] = this.#get({source_id: trans.bill_source_id, id: trans.bill_id});

      if (trans.settled)
        state.settled++
      else
        state.unsettled++;

      state.summary.process([trans]);
      this.#states.set(ref, state);
    };

    return this;
  }

  finalize() {
    this.#states.forEach(state => {
      state.debt = Math.trunc(state.summary.debt);
      state.balanced = state.debt < 1;
    });

    return this;
  }

  one(bill) {
    const [, state] = this.#get(bill);
    return state;
  }

  all() {
    return this.#states.values().toArray();
  }
}
