import {all, create} from 'mathjs';

const math = create(all, {});

export class Expression {
  static #rx = /^[\d+\-*/()., ]+$/;

  constructor(str) {
    try {
      if (Expression.#rx.test(str)) {
        const value = math.evaluate(str.replace(/,/g, '.'));
        if (typeof value === 'number') {
          this.value = value;
          return;
        }
      }
    }
    catch {
    }

    this.value = 0;
  }
}
