import {redirect} from 'react-router-dom';

import {Synchronization} from '../api/Synchronization';
import {BillRoutes} from '../AppRoutes';
import {Storage} from '../db/Storage';

function isReadOnly(billKey) {
  return Storage.bills.one(billKey).then(bill => bill.read_only);
}

export async function action({request, params}) {
  const billKey = BillRoutes.parseParams(params);
  const readOnly = await isReadOnly(billKey);
  if (!readOnly) {
    const updateKey = Storage.updateKey;
    await Storage.bills.delete(billKey, updateKey);
    Synchronization.commit();
  }
  return redirect('/');
}
