import classNames from 'classnames';
import React from 'react';

function CheckBox({set, label, valid, ...props}) {
  const checkClass = classNames('form-check-input', {'is-invalid': !valid});
  const id = `${set}.${label}`;
  return (
      <div className="form-check form-check-inline">
          <input {...props} id={id} name={set} value={label} type="checkbox" className={checkClass}/>
          <label className="form-check-label" htmlFor={id}>{label}</label>
      </div>
  );
}

export function CheckList({id, label, list, defaultValue, onChange, ...props}) {
  const onCheck = () => {
      const checked = document.querySelectorAll(`input[name=${id}]:checked`);
      let value = [];
      checked.forEach(elem => value.push(elem.value), value);
      onChange(value);
  };

  return (
      <div className='mb-3'>
          <label className="form-label">{label}</label>
          <div>
              {list.map(elem => <CheckBox {...props} key={elem} set={id} label={elem} defaultChecked={defaultValue.includes(elem)} onChange={onCheck}/>)}
          </div>
      </div>
  );
}
