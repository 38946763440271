import React from 'react';
import {Link} from 'react-router-dom';

export function SearchBar({search, title, ...props}) {
  return (
    <nav className="navbar sticky-top navbar-light bg-light">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand" replace>h_s</Link>
        <div className="d-flex">
          <input {...props} className="form-control form-control-sm m-0" name="search" value={search} placeholder="Szukaj..."/>
        </div>
      </div>
      <div className="container-fluid"><div className="small text-truncate text-secondary">{title}</div></div>
    </nav>
  );
}
