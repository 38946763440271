export class AppEvent {
  static SYNC_STATUS = 'sync-status';
  static PUSH_STATUS = 'push-status';
  static NOTIFICATIONS = 'notifications';

  #msg;

  constructor(msg) {
    this.#msg = msg;
  }

  static createSync(status) {
    return new AppEvent({type: AppEvent.SYNC_STATUS, status});
  }

  static createPush(hash) {
    return new AppEvent({type: AppEvent.PUSH_STATUS, hash});
  }

  static createNotifications(enabled) {
    return new AppEvent({type: AppEvent.NOTIFICATIONS, enabled});
  }

  sWPost() {
    // eslint-disable-next-line no-restricted-globals
    return self.clients.matchAll().then(clients => {
      for (const client of clients) {
        client.postMessage(this.#msg);
      }
    });
  }

  post() {
    window.postMessage(this.#msg);
    return Promise.resolve();
  }

  static addListener(handler) {
    window.addEventListener('message', handler);
      if (navigator.serviceWorker)
        navigator.serviceWorker.addEventListener('message', handler);
  }

  static removeListener(handler) {
    window.removeEventListener('message', handler);
      if (navigator.serviceWorker)
        navigator.serviceWorker.removeEventListener('message', handler);
  }
};
