export function parseRecordRef(str) {
  const ids = str.split(':').map(val => parseInt(val, 10));
  return {source_id: ids[0], id: ids[1]};
}

export function createRecordRef({source_id, id}) {
  return `${source_id}:${id}`;
}

export function createBillRecordRef({bill_source_id, bill_id}) {
  return `${bill_source_id}:${bill_id}`;
}

export function parseBillRecordRef(str) {
  const ids = str.split(':').map(val => parseInt(val, 10));
  return {bill_source_id: ids[0], bill_id: ids[1]};
}
