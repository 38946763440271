import React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {App} from './App';

const root = createRoot(document.getElementById('root'));
root.render(<App/>);

serviceWorkerRegistration.register();
