import React from 'react';

export function PleaseWait({children}) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
      <div className="ms-2">{children}</div>
    </div>
  );
}
