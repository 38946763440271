import {Db} from './Db';
import {createRecordRef} from './RecordRef';

export class RecordLog extends Db {
  #defaultValue;

  constructor(table, defaultValue) {
    super(table, defaultValue);
    this.#defaultValue = defaultValue;
  }

  #sieve(log) {
    let records = new Map();
    let outdated = new Set();

    for (const entry of log) {
      const ref = createRecordRef(entry);
      if (records.has(ref)) {
        outdated.add(entry);
      } else {
        records.set(ref, entry);
      }
    }

    records = Array.from(records.values());
    outdated = Array.from(outdated);
    return {records, outdated};
  }

  chronoSorted(collection) {
    return collection.sortBy('update_time');
  }

  #processLog(collection) {
    return this.chronoSorted(collection.reverse()).then(this.#sieve);
  }

  static #notDeleted(records) {
    return records.filter(elem => !elem.deleted);
  }

  #latest(key) {
    const collection = this.query(key);
    return this.#processLog(collection).then(result => RecordLog.#notDeleted(result.records)[0]);
  }

  add(record) {
    return super.add({...record, sent: 0});
  }

  delete(key, updateKey) {
    return this.#latest(key).then(record => record ? this.add({...record, ...updateKey, deleted: 1}) : null);
  }

  many(key) {
    const collection = key ? this.query(key) : this.all();
    return this.#processLog(collection).then(result => RecordLog.#notDeleted(result.records));
  }

  one(key) {
    return this.#latest(key).then(record => record ?? {...this.#defaultValue, ...key});
  }

  find(key) {
    return this.#latest(key).then(record => record ? [record, true] : [{...this.#defaultValue, ...key}, false]);
  }

  notSent(key) {
    const collection = key ? this.query(key) : this.all();
    return this.#processLog(collection).then(result => result.records.filter(record => !record.sent));
  }

  markSent(key) {
    const collection = key ? this.query(key) : this.all();
    return collection.modify({sent: 1});
  }

  markUnsent(key) {
    const collection = key ? this.query(key) : this.all();
    return collection.modify({sent: 0});
  }

  key(record) {
    const {id, source_id, updater_id, update_time} = record;
    return {id, source_id, updater_id, update_time};
  }

  ref(record) {
    const {id, source_id} = record;
    return {id, source_id};
  }

  prune() {
    const collection = this.all();
    return this.#processLog(collection).then(result => {
      const outdated = result.outdated.map(record => this.remove(this.key(record)));
      const deleted = result.records.map(record => {
        // usuwane są rekordy wysłane i usunięte lub usunięte gdzie indziej
        if (record.deleted && (record.sent || record.updater_id)) {
          return this.remove(this.ref(record));
        }

        return Promise.resolve();
      });

      return Promise.all([outdated, deleted]);
    });
  }
};
