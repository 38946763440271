import React, {useEffect, useState} from 'react';
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom';

import {Synchronization} from './api/Synchronization';
import {AppEvent} from './AppEvent';
import {BillRoutes, TransRoutes} from './AppRoutes';
import {action as billDeleteAction} from './routes/BillDelete';
import {BillEdit, loader as billLoader, action as billAction} from './routes/BillEdit';
import {Bills, loader as billsLoader, action as billsAction} from './routes/Bills';
import {BillSettle, action as billSettleAction, loader as billSettleLoader} from './routes/BillSettle';
import {BillTrans, loader as billTransLoader, action as billTransAction} from './routes/BillTrans';
import {action as cloudDeleteAction} from './routes/CloudConfigDelete';
import {CloudConfigEdit, loader as cloudLoader, action as cloudAction} from './routes/CloudConfigEdit';
import {action as transDeleteAction} from './routes/TransDelete';
import {TransEdit, loader as transLoader, action as transAction} from './routes/TransEdit';
import {Notifications} from './Notifications';

const billRoutes = new BillRoutes(BillRoutes.params);
const transRoutes = new TransRoutes({...TransRoutes.params, ...TransRoutes.billRefParams(BillRoutes.params)});

function AppLayout() {
  const [state, setState] = useState({cloud: {status: 'offline'}, push: {hash: null}, notifications: false, init: true});

  useEffect(() => {
    const onMsg = event => {
      switch (event.data.type) {
        case AppEvent.SYNC_STATUS:
          setState(prev => {
            return {...prev, cloud: {status: navigator.onLine ? event.data.status : 'offline'}};
          });

          if (event.data.status === 'online')
            Notifications.ask();

          break;

        case AppEvent.NOTIFICATIONS:
          setState(prev => {
            return {...prev, notifications: event.data.enabled};
          });
          break;

        case AppEvent.PUSH_STATUS:
          setState(prev => {
            return {...prev, push: {hash: event.data.hash}};
          });
          break;

        default:
          break;
      }
    };

    AppEvent.addListener(onMsg);

    if (state.init) {
      Synchronization.init();
      setState(prev => {
        return {...prev, init: false};
      });
    }

    return () => AppEvent.removeListener(onMsg);
  }, [state.init]);

  return <Outlet context={state}/>;
}

const appRoutes = {
  element: <AppLayout/>,
  children: [
    {
      path: '/',
      element: <Bills/>,
      loader: billsLoader,
      action: billsAction,
    },
    {
      path: billRoutes.path,
      element: <BillTrans/>,
      loader: billTransLoader,
      action: billTransAction,
    },
    {
      path: billRoutes.edit,
      element: <BillEdit/>,
      loader: billLoader,
      action: billAction,
    },
    {
      path: billRoutes.delete,
      action: billDeleteAction,
    },
    {
      path: billRoutes.settle,
      element: <BillSettle/>,
      loader: billSettleLoader,
      action: billSettleAction,
    },
    {
      path: transRoutes.edit,
      element: <TransEdit/>,
      loader: transLoader,
      action: transAction,
    },
    {
      path: transRoutes.delete,
      action: transDeleteAction,
    },
    {
      path: '/cloud/config/edit',
      element: <CloudConfigEdit/>,
      loader: cloudLoader,
      action: cloudAction,
    },
    {
      path: '/cloud/config/delete',
      action: cloudDeleteAction,
    },
  ]
};

export function App() {
  const router = createBrowserRouter([appRoutes]);
  return <RouterProvider router={router}/>;
}
