import {Api} from './Api';
import {Storage} from '../db/Storage';
import {Vapid} from '../vapid';

export class Push {
  static #subscribe() {
    // eslint-disable-next-line no-restricted-globals
    return self.registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: Vapid.pubKey,
    });
  }

  static #processSubscription() {
    // eslint-disable-next-line no-restricted-globals
    return self.registration.pushManager.getSubscription().then(subscription => {
      if (subscription)
        return;

      return Push.#subscribe().then(() => Push.reset());
    });
  }

  static #saveRegistration(response) {
    return Storage.setConfig({subscribedSourceId: response.source_id});
  }

  static #processRegistration() {
    return Storage.getConfig().then(({apiKey, sourceId, subscribedSourceId}) => {
      if (subscribedSourceId === sourceId)
        return;

      // eslint-disable-next-line no-restricted-globals
      return self.registration.pushManager.getSubscription().then(subscription => {
        return new Api(apiKey, sourceId).register({subscription}).then(response => Push.#saveRegistration(response));
      });
    });
  }

  static #decodeUrlBase64(str) {
    const padding = '='.repeat((4 - str.length % 4) % 4);
    const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    return base64 + padding;
  }

  static #verifyKey() {
    // eslint-disable-next-line no-restricted-globals
    return self.registration.pushManager.getSubscription().then(subscription => {
      if (!subscription)
        return;

      const currentKey = btoa(String.fromCharCode(...new Uint8Array(subscription.options.applicationServerKey)));

      if (currentKey !== Push.#decodeUrlBase64(Vapid.pubKey))
        return subscription.unsubscribe();
    });
  }

  static start() {
    return Storage.getConfig().then(({apiKey}) => {
      if (!apiKey)
        return;

        return Push.#verifyKey().then(() => Push.#processSubscription()).then(() => Push.#processRegistration());
    });
  }

  static reset() {
    return Storage.setConfig({subscribedSourceId: null});
  }

  static stop() {
    return Storage.getConfig().then(({apiKey, sourceId}) => new Api(apiKey, sourceId).unregister());
  }

  static get hash() {
    // eslint-disable-next-line no-restricted-globals
    return self.registration.pushManager.getSubscription().then(subscription => {
      if (subscription) {
        const json = JSON.stringify(subscription).replace(/\s+/g, '');
        const data = new TextEncoder().encode(json);
        return crypto.subtle.digest('SHA-1', data).then(hash => btoa(String.fromCharCode(...new Uint8Array(hash))));
      }

      return Promise.resolve(null);
    });
  }
};
