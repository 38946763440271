import React, {useState} from 'react';
import {Form, redirect, useLoaderData} from 'react-router-dom';

import {Synchronization} from '../api/Synchronization';
import {BillRoutes, TransRoutes} from '../AppRoutes';
import {CheckList} from '../components/forms/CheckList';
import {BackButton} from '../components/forms/BackButton';
import {CloneButton} from '../components/forms/CloneButton';
import {DeleteButton} from '../components/forms/DeleteButton';
import {SubmitButton} from '../components/forms/SubmitButton';
import {TextBox} from '../components/forms/TextBox';
import {Storage} from '../db/Storage';
import {Currency} from '../utils/Currency';
import {Expression} from '../utils/Expression';

export async function loader({params}) {
  const billKey = BillRoutes.parseParams(params);
  const bill = await Storage.bills.one(billKey);
  const transKey = {...TransRoutes.parseParams(params), ...TransRoutes.parseBillParams(params)};
  const [trans, edit] = await Storage.transactions.find(transKey);
  return {bill, trans, edit};
}

function createTrans({label, from, to, expression}) {
  const value = new Expression(expression).value;
  const amount = Currency.fromFloat(value).amount;
  return {
    from,
    expression: expression.trim(),
    amount,
    label: label.trim(),
    to,
  };
}

function createUpdates(data) {
  // fromEntries() nie obsługuje poprawnie wielu wartości o tej samej nazwie
  return {
    ...Object.fromEntries(data),
    from: data.getAll('from'),
    to: data.getAll('to'),
  };
}

function parseForm(data) {
  const updates = createUpdates(data);
  return {data: createTrans(updates), action: updates.action};
}

function saveTrans(trans, transKey, updateKey) {
  return Storage.transactions.add({...trans, ...transKey, ...updateKey});
}

export async function action({request, params}) {
  const form = await request.formData().then(parseForm);
  const transRef = form.action === 'clone' ? Storage.nextKey : TransRoutes.parseParams(params);
  const transKey = {...transRef, ...TransRoutes.parseBillParams(params)};
  const updateKey = Storage.updateKey;
  await saveTrans(form.data, transKey, updateKey);
  Synchronization.commit();
  const billKey = BillRoutes.parseParams(params);
  return redirect(new BillRoutes(billKey).path);
}

function validateLabel(value) {
  return value.trim();
}

function validateEndpoints({from, to}) {
  const equal = from.length === to.length && from.every(elem => to.includes(elem));
  return {from: from.length && !equal, to: to.length && !equal};
}

function validateExpression(expression) {
  return expression.value > 0;
}

function createInitialState(trans) {
  const expression = new Expression(trans.expression);
  return {
    label: validateLabel(trans.label),
    amount: Currency.fromFloat(expression.value).str,
    expression: validateExpression(expression),
    ...validateEndpoints(trans),
    trans,
  };
}

function DeleteForm({trans}) {
  return (
    <Form method="post" action={new TransRoutes(trans).delete} replace>
      <DeleteButton/>
    </Form>
  );
}

export function TransEdit() {
  const {bill, trans, edit} = useLoaderData();
  const [state, setState] = useState(createInitialState(trans));

  const onExpressionChange = value => {
    setState(prev => {
      const expression = new Expression(value);
      const amount = Currency.fromFloat(expression.value).str;
      return {...prev, amount, expression: validateExpression(expression)};
    });
  };

  const onFromChange = value => {
    setState(prev => {
      return {
        ...prev,
        ...validateEndpoints({...prev.trans, from: value}),
        trans: {...prev.trans, from: value}};
    });
  };

  const onToChange = value => {
    setState(prev => {
      return {
        ...prev,
        ...validateEndpoints({...prev.trans, to: value}),
        trans: {...prev.trans, to: value}
      };
    });
  };

  const onLabelChange = value => {
    setState(prev => {
      return {...prev, label: validateLabel(value)};
    });
  };

  const readOnly = bill.read_only || trans.settled;
  const backPath = new BillRoutes(bill).path;
  return (
    <>
      <Form method="post" id="trans-edit" replace>
        <CheckList id="from" label="Płaci" list={bill.actors} defaultValue={trans.from} valid={state.from} disabled={readOnly} onChange={onFromChange}/>
        <TextBox id="expression" placeholder="Kwota" label="Kwotę" defaultValue={trans.expression} help={`=${state.amount}`} valid={state.expression}
          disabled={readOnly} onChange={onExpressionChange}/>
        <TextBox id="label" placeholder="Krótki opis co i jak" label="Za" defaultValue={trans.label} valid={state.label} disabled={readOnly}
          onChange={onLabelChange}/>
        <CheckList id="to" label="Komu" list={bill.actors} defaultValue={trans.to} valid={state.to} disabled={readOnly} onChange={onToChange}/>
        {readOnly ? <BackButton to={backPath}/> : <SubmitButton valid={state.expression && state.label && state.from && state.to}/>}
      </Form>
      {readOnly ? null : <DeleteForm trans={trans}/>}
      {!readOnly && edit ? <CloneButton form="trans-edit"/> : null}
    </>
  );
}
